/*jslint white: true, nomen: true */
/*global angular, googleApiKey */

(function() {
  'use strict';

  angular.module('app', [
      'ngResource',
      'uiGmapgoogle-maps',
      'ngRoute',
      'app.common',
      'app.homepage',
      'app.about',
      'app.team',
      'app.investments',
      'app.contact'
    ])
    .config(['$interpolateProvider', function($interpolateProvider) {
      $interpolateProvider.startSymbol('<%');
      $interpolateProvider.endSymbol('%>');
    }])
    .config(['uiGmapGoogleMapApiProvider', function(uiGmapGoogleMapApiProvider) {
      uiGmapGoogleMapApiProvider.configure({
        key: googleApiKey,
        v: '3.24',
        libraries: 'places'
      });
    }])
    .config(['$locationProvider', function($locationProvider) {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: true,
        rewriteLinks: false
      });
    }])
    .config(['$compileProvider', function($compileProvider) {
      $compileProvider.debugInfoEnabled(false);
    }])
    .run(['$route', '$rootScope', '$location', function($route, $rootScope, $location) {

      /*init fastclick*/
      if ('addEventListener' in document) {
        document.addEventListener('DOMContentLoaded', function() {
          FastClick.attach(document.body);
        }, false);
      }
    }]);

    angular.module('app.common', [])
    .filter('trustAsHtml', ['$sce', function($sce) {
      return $sce.trustAsHtml;
    }]);
    angular.module('app.homepage', ['ngSanitize']);
    angular.module('app.about', ['ngSanitize']);
    angular.module('app.team', ['ngSanitize']);
    angular.module('app.investments', []);
    angular.module('app.contact', []);
}());

$(function(){

  'use strict';

  function initNavbar () {
    if (!$('section:first').is('.parallax, #home, .splash, .page-header')) {
      $('#topnav').addClass('scroll');
      $('body').addClass('top-padding');
    }

    if ($('section:first').is('#home') && $('#home').hasClass('bordered')) {
      $('#topnav').addClass('top-space');
    }

    $(window).scroll(function() {

      // if($('section:first').is('.parallax, #home, .splash, .page-header')){
      //   if ($(window).scrollTop() >= 100 ) {
      //     $('#topnav').addClass('scroll');
      //   } else{
      //     $('#topnav').removeClass('scroll');
      //   }
      // }

      var filters = $('#filters');
      if(filters.length && !filters.hasClass('no-fix')){
        if ($(window).scrollTop() >= $('.page-title:first').height() + 30) {
          filters.addClass('fixed');
        } else{
          filters.removeClass('fixed');
        }
      }

    }).trigger('scroll');

    $('.navbar-toggle').on('click', function(event) {
      $(this).toggleClass('open');
      $('#navigation').slideToggle(400);
      $('.cart, .search').removeClass('open');
    });

    $('.cart').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      if ($(window).width() < 768) {
        if ($('#navigation').is(':visible')) {
          $('.navbar-toggle').click();
        }
        $('.search').removeClass('open');
        $(this).toggleClass('open');
      }
    });

    $('.search').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      if ($(window).width() < 768) {
        if ($('#navigation').is(':visible')) {
          $('.navbar-toggle').click();
        }
        $('.cart').removeClass('open');
        $(this).toggleClass('open');
      }
    });

    $('.shopping-cart, .search-form').on('click', function(e) {
      event.stopPropagation();
    });

    $('body').on('click', function(event) {
      $('.cart, .search').removeClass('open');
    });

    $('.navigation-menu>li').slice(-2).addClass('last-elements');

    $('.navigation-menu li.has-submenu a[href="#"]').on('click', function(e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
      }
    });
  }

  function initHomeSlider() {

    $('#home-slider img').each(function(index, el) {
      var slide = $(this).parent('li');
      var image = $(this).attr('src');

      $(slide).prepend($('<div class="slide-image"></div>').css('background-image', 'url('+image+')'));

      if (navigator.userAgent.indexOf("Firefox") != -1 && $('#home').hasClass('bordered')) {
        $('.slide-image').addClass('ff-fix');
      }

      $(this).remove();
    });

    var options = {
      prevText: '',
      nextText: '',
      keyboard: false,
    };

    if ($('#home-slider .slides > li').length < 2) {
      options.directionNav = false
    }

    if ($('#home-slider').hasClass('kenburn')) {

      options.start = function () {
        $('#home-slider').find(".slides > li.flex-active-slide > .slide-image").each(function () {
          var $content = $(this);
          $content.css({
            '-webkit-transform': 'scale(1.2)',
            '-moz-transform': 'scale(1.2)',
            'transform': 'scale(1.2)',
          });
        })
      }

      options.before = function () {
        $('#home-slider').find(".slides > li > .slide-image").each(function () {
          var $content = $(this);
          $content.css({
            '-webkit-transform': 'scale(1)',
            '-moz-transform': 'scale(1)',
            'transform': 'scale(1)',
          });
        })
      }

      options.after = function () {
        $('#home-slider').find(".slides > li.flex-active-slide > .slide-image").each(function () {
          var $content = $(this);
          $content.css({
            '-webkit-transform': 'scale(1.2)',
            '-moz-transform': 'scale(1.2)',
            'transform': 'scale(1.2)',
          });
        })
      }
    }

    $('#home-slider').flexslider(options);

    $('#text-rotator').flexslider({
      controlNav: false,
      directionNav: false
    })
  }

  function init () {
    initNavbar();
    initHomeSlider();

  //   $('.team-page .panel-body').each(function(index, el) {
  //     $(el).css('max-height', '300px').append('<a class="more" href="#">+</a><a class="less" href="#">-</a>');

  //   });
		// $('.team-page .panel-body').dotdotdot({
		// 	after: "a.more",
		// 	callback: dotdotdotCallback
		// });
  //   $('.team-page .panel-body').on('click', 'a', function (e) {
  //     e.preventDefault();
		// 	var div = $(this).closest('.team-page .panel-body');
		// 	if ($(this).text() == "+") {
		// 		var div = $(this).closest('.team-page .panel-body');
		// 		div.trigger('destroy').find('a.more').hide();
  //       $(".team-page .panel-body").removeClass("is-open");
  //       div.addClass("is-open");
		// 		div.css('max-height', '');
		// 		//$("a.less", div).show(600);

		// 	} else {
		// 		$(this).closest('.team-page .panel-body').css("max-height", "300px").dotdotdot({
		// 			after: "a",
		// 			callback: dotdotdotCallback
		// 		});
  //       //div.removeClass("is-open");
  //        $(".team-page .panel-body").removeClass("is-open");
  //       div.addClass("is-open");
		// 	//	$("a.more", div).show();
		// 	}
  //   });
  //   function dotdotdotCallback(isTruncated, originalContent) {
		// 	if (!isTruncated) {
		// 		$("a", this).remove();
		// 	}
  //     if(!$(".team-page .panel-body").hasClass("is-open")) {
  //         var div = $(this).closest('.team-page .panel-body');
  //         $("a.less", div).hide();
  //     }
  //   }

// open close toggle for team page
  var showMore = $('.team-page .panel-body a.toggle');
  var panel = $('.team-page .panel-body');

  if(!$(panel).hasClass('is-open')){
      $('.bio-wrap').addClass('ellipsis');
    }

  $('.bio-wrap').css('height', '200px');
  $(showMore).on("click", function(e) {
    e.preventDefault();
    if($(this).text() == '+') {
    $(this).text('-');
    } else {
      $(this).text('+');
    }

    var $this = $(this).closest(panel);
    $(panel).not($this).removeClass("is-open");
    $(panel).not($this).css('height', '300px');
    $(panel).not($this).find(showMore).text('+');
    $this.toggleClass("is-open");

 var textHeight = $this.find('.bio-wrap')[0].scrollHeight + 75;
  if ($(this).closest(panel).hasClass('is-open')) {
    $(this).closest(panel).css('height', textHeight);
    $('.bio-wrap').addClass('ellipsis');
    $this.find('.bio-wrap').removeClass('ellipsis');
  } else {
    $(this).closest(panel).css('height', '300px');
    $('.bio-wrap').addClass('ellipsis');

  }
});

  // make footer stick to bottom of page
  // var pageHeight = $("body").height(),
  //     headerHeight = $("header#topnav").height() + 107,
  //     reducedPageHeight = pageHeight - headerHeight;

  //     $("#page-wrap").css('height', reducedPageHeight );
  // dynamically resize contact page map

  var contactPageHeight = $(".contact-page").height(),
      titleHeight = $(".page-title").height(),
      mapHeight = contactPageHeight - titleHeight - 95;

      $(".contact-page #mapper").css('height', mapHeight );

  var slideshowHeight = $(window).height() - 100;

  $("#home").css("height", slideshowHeight);

}

  init();

});
