angular.module('app.homepage')
.controller('HomepageCtrl', ['$scope', 'CMSService',
  function ($scope, CMSService) {

    CMSService.loadContents('about_copy').then(function(result) {
      $scope.about_hero = result.data.contents[0].fields;
    });

    CMSService.loadContents('contact_us').then(function(result) {
      $scope.contact_us = result.data.contents[0].fields;
      
      latitude = $scope.contact_us.latitude; 
      longitude = $scope.contact_us.longitude;
      console.log($scope.contact_us);
      init();
    });

}]);
