angular.module('app.investments')
.controller('InvestmentCtrl', ['$scope', '$http', '$window',
  function ($scope, $http, $window) {

  $scope.content = [];

  $scope.loadInvestment = function() {
    $http.get($window.location.href)
      .success(function(response) {
        if (response.success) {
          $scope.investment = response.investment;
        } else {
          console.log('error');
        }
    });
  };

  $scope.loadInvestment();

}]);
