angular.module('app.contact')
.controller('ContactCtrl', ['$scope', 'CMSService',
  function ($scope, CMSService) {

    CMSService.loadContents('contact_us').then(function(result) {
      $scope.contact_us = result.data.contents[0].fields;
      
      latitude = $scope.contact_us.latitude; 
      longitude = $scope.contact_us.longitude;
      
      init();
    });

}]);
