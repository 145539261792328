angular.module('app.team')
.controller('TeamCtrl', ['$scope', 'CMSService',
  function ($scope, CMSService) {

    CMSService.loadContents('team').then(function(result) {
      $scope.members = result.data.contents;
    });

    $scope.showMore = function(e) {
      e.preventDefault();

      if ($(e.currentTarget).text() == '+') {
        $(e.currentTarget).text('-');
      } else {
        $(e.currentTarget).text('+');
      }

      var showMore = $('.team-page .panel-body a.toggle');
      var panel = $('.team-page .panel-body');

      var $this = $(e.currentTarget).closest(panel);
      $(panel).not($this).removeClass("is-open");
      $(panel).not($this).css('height', '300px');
      $(panel).not($this).find(showMore).text('+');
      $this.toggleClass("is-open");

      var textHeight = $this.find('.bio-wrap')[0].scrollHeight + 75;
      if ($(e.currentTarget).closest(panel).hasClass('is-open')) {
        $(e.currentTarget).closest(panel).css('height', textHeight);
        $('.bio-wrap').addClass('ellipsis');
        $this.find('.bio-wrap').removeClass('ellipsis');
      } else {
        $(e.currentTarget).closest(panel).css('height', '300px');
        $('.bio-wrap').addClass('ellipsis');
      }
    };
    

}]);
