angular.module('app.investments')
.controller('InvestmentsCtrl', ['$scope', '$http',
  function ($scope, $http) {

  $scope.content = [];

  $scope.loadInvestments = function() {
    $http.get('/investments')
      .success(function(response) {
        if (response.success) {
          $scope.investments = response.investments;
        } else {
          console.log('error');
        }
    });
  };

  $scope.loadInvestments();

}]);
